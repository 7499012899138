import { ActionFunctionArgs, json, TypedResponse } from "@remix-run/node";
import { useFetcher } from "@remix-run/react";
import { userPrefs } from "~/cookies.server";
import { jauntGet } from "~/lib";

interface ActionResponse {
  field: string;
  success: boolean;
}

export const action = async ({
  request,
}: ActionFunctionArgs): Promise<TypedResponse<ActionResponse>> => {
  const formData = await request.formData();

  const token = formData.get("token");
  if (typeof token === "string") {
    const response = await jauntGet("v0/projects", token);

    if (response.ok) {
      return json(
        { field: "Token", success: true },
        { headers: { "Set-Cookie": await userPrefs.serialize(token) } },
      );
    }
  }

  return json({ field: "Token", success: false });
};

function ResultMessage(params: ActionResponse) {
  if (params.success) {
    return <p>{params.field} successfully set.</p>;
  }
  return <p>Failed to set {params.field}!</p>;
}

export default function Settings() {
  const fetcher = useFetcher<typeof action>();
  const data = fetcher.data;

  return (
    <div>
      {data ? (
        <ResultMessage field={data.field} success={data.success} />
      ) : undefined}
      <p>Set token here!</p>
      <fetcher.Form method="post">
        <input
          aria-label="Set token"
          defaultValue={""}
          name="token"
          placeholder="Token"
          type="text"
        />
        <button type="submit">Set token</button>
      </fetcher.Form>
    </div>
  );
}
